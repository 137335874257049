import * as React from "react"

import Layout from "../../components/Layout"
import LuxuryResaleConfirmation from "../../components/LuxuryResalePage/LuxuryResaleConfirmation"


function luxuryresalesubscriptionconfirmed({ data }) {
  return (
    <Layout>
      <LuxuryResaleConfirmation />

    </Layout>
  )
}

export default luxuryresalesubscriptionconfirmed
